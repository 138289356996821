<template>
  <AppBottomNav :items="items" :back="back" :next="next" />
</template>

<script>
import AppBottomNav from "./AppBottomNav";

export default {
  props: {
    back: String,
    next: String,
  },
  components: {
    AppBottomNav,
  },
  data() {
    return {
      items: [
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Einmaleins",
          to: "/einmaleins",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Hintergrundwissen",
          to: "/einmaleins/hintergrundwissen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Kernaufgaben",
          to: "/einmaleins/hintergrundwissen/kernaufgaben",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Ableitungsstrategien",
          to: "/einmaleins/hintergrundwissen/ableitungsstrategien",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Aufgaben und Darstellungen vernetzen",
          to: "/einmaleins/hintergrundwissen/aufgaben-und-darstellungen-vernetzen",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Übergang Diagnose",
          to: "/einmaleins/uebergang",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Diagnose & Förderung",
          to: "/einmaleins/diagnose-und-foerderung",
        },
        {
          disabled: false,
          exact: true,
          link: true,
          text: "Kompetenzliste",
          to: "/einmaleins/check",
        },
      ],
    };
  },
};
</script>